import React from 'react'
import Questiontable from '../../Components/Questions/QuestionsTable'

function Question() {
  return (
    <div>
      <Questiontable/>
    </div>
  )
}

export default Question