import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = 'https://api.lecturesoft.org/v1/api'


const login = (email, password) =>{
    const value = {"emailAddress" : email, "password": password}
   return axios.post(BASEURL + '/admin_login', value, { headers: authHeader() })
}

const createTeacherSkillGroup= (groupName) =>{
    const value = {"groupName" : groupName, }

    return axios.post(BASEURL + '/create_skill_group', value, { headers: authHeader() })
}

 const updateSkillGroup = (groupId, groupName) =>{
    const value  = {"groupId" : groupId, "groupName" :groupName}
    return axios.post(BASEURL + `/update_skill_group`, value, { headers: authHeader() })
 }

 const addSkillComponent = (componentName, groupId) =>{
    const value  = {"groupId" : groupId, "componentName" : componentName}
    return axios.post(BASEURL + '/create_skill_component', value, { headers: authHeader() })
 }
 const updateSkillComponent = (componentId, componentName) =>{
   const value  = {"componentName" : componentName, "componentId" : componentId,}
   return axios.post(BASEURL + '/update_skill_component', value, { headers: authHeader() })
}
const createQuestion = (question, groupId, componentId) =>{
   const value  = {"groupId" : groupId, "question" : question,  "componentId" : componentId}
   return axios.post(BASEURL + '/add_question', value, { headers: authHeader() })
}
const updateQuestion = (question, groupId, componentId, questionId) =>{
   const value  = {"groupId" : groupId, "question" : question,  "componentId" : componentId, "questionId":questionId}
   return axios.post(BASEURL + '/update_question', value, { headers: authHeader() })
}

const bulkUser= (user) =>{
   const formData = new FormData();
   formData.append('staffRecord', user);
   return axios.post(BASEURL + '/bulk_teacher_upload', formData, { headers: {    
      'x-api-key': '16022024',
      'Content-Type': 'multipart/form-data'
  } })
}


const postServices = {
    login, 
    createTeacherSkillGroup,
    updateSkillGroup,
    addSkillComponent,
    updateSkillComponent,
    createQuestion,
    updateQuestion,
    bulkUser
}

export default postServices