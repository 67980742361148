import React, { useEffect, useState } from 'react'
import getServices from '../../Services/get-services'
import Loading from '../../Components/SelectValue/Loading'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../SelectValue/Button';
import DeleteQuestion from './DeleteQuestion';
import CreateEditQuestion from './CreateQuestion';
import Inputtt from '../SelectValue/Inputtt';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#7A8699',
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


function Questiontable() {
    const [trans, setTrans] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [category, setCategory] = useState('')
    const [skillId, setSkillId] = useState('')
    const [catId, setCatId] = useState('')
    const [id, setId] = useState('')
    const [searchh, setSearch] = useState('')
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleClose1 = () => setOpen1(false)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    useEffect(() =>{
        setLoading(true)
        getServices.getListAllQuestion().then(
         res =>{
            setLoading(false)
            if(res.data.statusCode === '200'){
                setTrans(res.data.result)
            }
         }   
        ).catch(
            error =>{
                console.log(error)
            }
        )
    }, [])
    const filteredData = trans.filter(
      person => {
        const searchLowerCase = searchh.toLowerCase();
        return (
          person
          .question
          .toLowerCase()
          .includes(searchLowerCase) 
        );
      }
    );
  return (
    <div>
    <Loading
        open={loading}
    />
    <CreateEditQuestion
        open={open}
        handleClose={handleClose}
        id={id}
        categoryy={category}
        skillId={skillId}
        catId={catId}
    />
    <DeleteQuestion
        open={open1}
        handleClose={handleClose1}
        id={id}
        name={category}
    />
      
        <div className=' rounded-xl border bg-white border-primary-color p-4 my-6'>
       <ToastContainer

       />
       <div className=' mb-5'>
        <Button
            name={'Create Question'}
            onClick={() =>{
                setOpen(true)
                setCategory('')
                setId('')
                setSkillId('')
                setCatId('')
            }}
        />
       </div>
      
     <h1 className=' text-primary-color font-bold text-[24px] mb-4'>List of Questions</h1>

     <div className=' mb-3'>
      <Inputtt
        placeholder={'Search by name'}
        onChange={setSearch}
      />
     </div>
    
     <Paper className=' mx-auto overflow-auto ' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>No</StyledTableCell>
            <StyledTableCell>Question</StyledTableCell>
            <StyledTableCell align="left">Group name</StyledTableCell>
            <StyledTableCell align="left">Component name</StyledTableCell>
            <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tran, index) => (
                
            <TableRow
              key={tran.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left">{index +1}</TableCell>
            <TableCell align="left">{tran.question}</TableCell>
            <TableCell align="left">{tran.group_name}</TableCell>
            <TableCell align="left">{tran.component_name}</TableCell>
              <TableCell align="left" 
              
              >
              <EditIcon 
              onClick={() =>{
                setId(tran.id)
                setCategory(tran.question)
                setOpen(true)
                setCatId(tran.component_id)
                setSkillId(tran.group_id)
              }}
              className='cursor-pointer'/>
              <DeleteIcon
              onClick={() =>{
                setId(tran.id)
                setCategory(tran.question)
                setOpen1(true)
              }}
               className=' cursor-pointer'/>
              </TableCell>
           

              
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {filteredData.length === 0 &&  <p className=' text-center font-bold text-red-700 mt-5'>No Data</p>}
    </Paper>
    </div>
    </div>
  )
}

export default Questiontable