import React, { useEffect, useState } from 'react'
import DashCom from './DashCom'
import getServices from '../../Services/get-services'
import Loading from '../../Components/SelectValue/Loading'

function MainDashboard() {
  const [ques, setQues] = useState(0)
  const [users, setUSers] = useState(0)
  const [ass, setAss] = useState(0)
  const [skilGroup, setSkillGroup] = useState(0)
  const [com, setCom] = useState(0)
  const [loading, setLoading] = useState(false)
  useEffect(() =>{
    setLoading(true)
    getServices.adminCount().then(
     res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){
            setQues(res.data.result.noOfQuestions)
            setUSers(res.data.result.noOfTeachers)
            setAss(res.data.result.noOfAssessmentTaken)
            setSkillGroup(res.data.result.noOfSkillGroups)
            setCom(res.data.result.noOfSkillComponents)
        }
     }   
    ).catch(
        error =>{
            console.log(error)
        }
    )
}, [])

  return (
    <div>
    <Loading
      open={loading}
    />
 <div className=' grid md:grid-cols-3 gap-5 sm:grid-cols-2'>
      <DashCom
        count={users}
        name={'Number of users'}
        link={'/users'}
      />
      <DashCom
        count={ques -1}
        name={'Number of Questions'}
        link={'/question'}
      />
       <DashCom
        count={ass}
        name={'Number of Assessment answered'}
        link={'/assessment'}
      />
       <DashCom
        count={skilGroup}
        name={'Number of Skill Group'}
        link={'/skillgroup'}
      />
       <DashCom
        count={com}
        name={'Number of Skill component'}
        link={'/skillcomponent'}
      />
    </div>
    </div>
   
  )
}

export default MainDashboard