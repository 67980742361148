import React from 'react';

const PastQuestionCom = ({ questionData }) => {
  return (
    <div className='overflow-x-auto'>
    <table className="border-collapse table-auto w-full  my-6 overflow-x-scroll min-w-max overflow-scroll">
      <thead>
        <tr className=' bg-white'>
        <th className="border p-2">No</th>
          <th className="border p-2">Question</th>
          <th className='border p-2'>Never <br />0</th>
            <th className='border p-2'>Rarely <br />1</th>
            <th className='border p-2'>Sometimes <br />2</th>
            <th className='border p-2'>Often <br />3</th>
            <th className='border p-2'>Always <br />4</th>
        </tr>
      </thead>
      <tbody>
  {questionData?.map((question, index) => (
    <tr className='bg-white' key={index}>
    <td className="border p-2 ">{index + 1}</td>
      <td className="border p-2 w-[95vh]">{question.question}</td>
      <td className="border pl-6 p-2 ">
        <input
          type="radio"
          name={`question_${index}`}
          value="0"
          checked={question.answer === (question.component_name === "Traditional Lecture" ? "4" : "0")}
          readOnly
        />
      </td>
      <td className="border pl-7 p-2">
        <input
          type="radio"
          name={`question_${index}`}
          value="1"
          checked={question.answer === (question.component_name === "Traditional Lecture" ? "3" : "1")}
          readOnly
        />
      </td>
      <td className="border pl-10 p-2">
        <input
          type="radio"
          name={`question_${index}`}
          value="2"
          checked={question.answer === "2"}
          readOnly
        />
      </td>
      <td className="border pl-6 p-2">
        <input
          type="radio"
          name={`question_${index}`}
          value="3"
          checked={question.answer === (question.component_name === "Traditional Lecture" ? "1" : "3")}
          readOnly
        />
      </td>
      <td className="border pl-7 p-2">
        <input
          type="radio"
          name={`question_${index}`}
          value="4"
          checked={question.answer === (question.component_name === "Traditional Lecture" ? "0" : "4")}
          readOnly
        />
      </td>
    </tr>
  ))}
</tbody>

    </table>
    </div>
  );
};

export default PastQuestionCom;
