import  React, { useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../Components/SelectValue/Loading';
import Button from '../SelectValue/Button';
import postServices from '../../Services/post-services';
import Inputtt from '../SelectValue/Inputtt';
import getServices from '../../Services/get-services';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function CreateEditQuestion({open, handleClose, id, skillId, categoryy, catId}) {
   
     const [category, setCategory] = useState('')
     const [idd, setId] = useState('')
     const [skillIdd, setSkillId] = useState('')
     const [trans, setTrans] = useState([])
     const [cat, setCat] = useState([])
     const [catValue, setCatValue] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
     setId(id)
     setCategory(categoryy)
     setSkillId(skillId)
     setCatValue(catId)
    }, [id, categoryy, skillId, catId])

    useEffect(() =>{
        getServices.liskSkillGroup().then(
         res =>{
           
            if(res.data.statusCode === '200'){
                setTrans(res.data.result)
            }
         }   
        ).catch(
            error =>{
                console.log(error)
            }
        )
    }, [])

    useEffect(() =>{
      setLoading(true)
      getServices.getListSkillComponent(skillIdd).then(
       res =>{
          setLoading(false)
          if(res.data.statusCode === '200'){
              setCat(res.data.result)
          }
       }   
      ).catch(
          error =>{
              console.log(error)
          }
      )
  }, [skillIdd])

   const submit = (e) =>{
    e.preventDefault()
      setLoading(true)
      
       if(idd.length >0){
        postServices.updateQuestion( category,skillIdd, catValue, idd).then(
            res =>{
                setLoading(false)
                if(res.data.statusCode === '200'){
                    toast.success(res.data.message, {
                        onClose: () => {
                          window.location.reload()
                        }
                      })
                }else{
                    setLoading(false)
                    toast.error(res.data.message,
                        )
                }
            }    
        ).catch(
            error =>{
                setLoading(false)
            }
        )
    
       }else{
        postServices.createQuestion(category,skillIdd, catValue).then(
            res =>{
                setLoading(false)
                if(res.data.statusCode === '200'){
                    toast.success(res.data.message, {
                        onClose: () => {
                          window.location.reload()
                        }
                      })
                }else{
                    setLoading(false)
                    toast.error(res.data.message,
                        )
                }
            }    
        ).catch(
            error =>{
                setLoading(false)
            }
        )
    
       }
       
      
   }
   
return (
  <div>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style} className=' rounded-lg p-6 pt-10'> 
        <Loading
        open={loading} />
       
          <div className=' '>
         
              <div className=' flex justify-end mb-3'>
                 
                  <div>
                  <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                  </div>
              </div>
              <form onSubmit={submit}>
               
               <Inputtt
                label={"Question name"}
                value={category}
                onChange={setCategory}
               />
                <div>
                <label className=' block mb-2'>Select Skill Group</label>
                <select
                required
                className=' w-full bg-[#F7F8FB]  text-black font-bold outline-0 border border-gray-300 p-3 rounded-lg'
                value={skillIdd}
                onChange={(e) => setSkillId(e.target.value)}
                >
                <option value=''>Select value</option>
                {trans.map((e, index) =>{
                    return <option key={index} value={e.groupId}>{e.groupName}</option>
                })}

                </select>
            </div>
            {skillIdd?.length > 0 &&
              <div className=' my-2'>
                <label className=' block mb-2'>Select Category name</label>
                <select
                required
                className=' w-full bg-[#F7F8FB]  text-black font-bold outline-0 border border-gray-300 p-3 rounded-lg'
                value={catValue}
                onChange={(e) => setCatValue(e.target.value)}
                >
                <option value=''>Select value</option>
                {cat.map((e, index) =>{
                    return <option key={index} value={e.componentId}>{e.componentName}</option>
                })}

                </select>
            </div>
            }
                <div className=' my-4'>
                <Button
                    name={"Send"}
                />
                </div>
              </form>
             
          </div>
        </Box>
      </Fade>
    </Modal>
  </div>
);
}