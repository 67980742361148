import React from 'react'
import SkilledGroupTable from '../../Components/SkillGroup/SkillGroupTable'

function SkillGroup() {
  return (
    <div>
      <SkilledGroupTable/>
    </div>
  )
}

export default SkillGroup