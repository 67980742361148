import React, { useEffect, useState } from 'react'
import getServices from '../../Services/get-services'
import Loading from '../../Components/SelectValue/Loading'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Inputtt from '../SelectValue/Inputtt';
import { Link } from 'react-router-dom';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#7A8699',
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


function AssessmentTable() {
    const [trans, setTrans] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
   
    const [searchh, setSearch] = useState('')
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    useEffect(() =>{
        setLoading(true)
        getServices.getAccessment().then(
         res =>{
            setLoading(false)
            if(res.data.statusCode === '200'){
                setTrans(res.data.result)
            }
         }   
        ).catch(
            error =>{
                console.log(error)
            }
        )
    }, [])
    const filteredData = trans.filter(
      person => {
        const searchLowerCase = searchh.toLowerCase();
        return (
          person
          .title
          .toLowerCase()
          .includes(searchLowerCase) ||
          person?.teacherDetails?.firstname
          .toLowerCase()
          .includes(searchLowerCase) ||
          person?.teacherDetails?.lastname
          .toLowerCase()
          .includes(searchLowerCase) ||
          person?.teacherDetails?.email_address
          .toLowerCase()
          .includes(searchLowerCase) 
        );
      }
    );
  return (
    <div>
    <Loading
        open={loading}
    />
    
      
        <div className=' rounded-xl border bg-white border-primary-color p-4 my-6'>
       <ToastContainer

       />
       <div className=' mb-5'>
       
       </div>
      
     <h1 className=' text-primary-color font-bold text-[24px] mb-4'>List of Assessment</h1>

     <div className=' mb-3'>
      <Inputtt
        placeholder={'Search by title, name, email'}
        onChange={setSearch}
      />
     </div>
    
     <Paper className=' mx-auto overflow-auto ' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Teacher personal</StyledTableCell>
            <StyledTableCell align="left">Teaching skills</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left">Teacher name</StyledTableCell>
            <StyledTableCell align="left">Teacher email</StyledTableCell>
            <StyledTableCell align="left">Question answered</StyledTableCell>
            <StyledTableCell align="left">Report</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tran, index) => (
                
            <TableRow
              key={tran.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left">{tran.title}</TableCell>
            <TableCell align="left">{tran.teacher_personal}</TableCell>
            <TableCell align="left">{tran.teaching_skill}</TableCell>
            <TableCell align="left">{tran.inserted_dt}</TableCell>
            <TableCell align="left">{tran?.teacherDetails.firstname} {tran?.teacherDetails.lastname}</TableCell>
            <TableCell align="left">{tran?.teacherDetails.email_address}</TableCell>
            <TableCell align="center"><Link to={`/assessment/pastquestion/${tran.assessment_id}/${tran?.teacherDetails.firstname} ${tran?.teacherDetails.lastname}`}><span className=' text-blue-400 underline'>View </span></Link> </TableCell>
            <TableCell align="center"><Link to={`/assessment/reportdetails/${tran.assessment_id}/${tran?.teacherDetails.firstname} ${tran?.teacherDetails.lastname}`}><span className=' text-blue-400 underline'>View </span></Link> </TableCell>
            
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {filteredData.length === 0 &&  <p className=' text-center font-bold text-red-700 mt-5'>No Data</p>}
    </Paper>
    </div>
    </div>
  )
}

export default AssessmentTable