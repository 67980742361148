import React, { useEffect, useState } from 'react'
import getServices from '../../Services/get-services'
import Loading from '../../Components/SelectValue/Loading'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../SelectValue/Button';
import CreateEditSkillGroup from './CUSkillCom';
import DeleteSkillCom from './DeleteSkillCom';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#7A8699',
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


function SkillComponentTable() {
    const [trans, setTrans] = useState([])
    const [com, setCom] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [category, setCategory] = useState('')
    const [id, setId] = useState('')
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleClose1 = () => setOpen1(false)
    const [group, setGroup] = useState('LEA3910')
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    useEffect(() =>{
        
        getServices.liskSkillGroup().then(
         res =>{
           
            if(res.data.statusCode === '200'){
                setTrans(res.data.result)
            }
         }   
        ).catch(
            error =>{
                console.log(error)
            }
        )
    }, [])
    useEffect(() =>{
        setLoading(true)
        getServices.getListSkillComponent(group).then(
         res =>{
            setLoading(false)
            if(res.data.statusCode === '200'){
                setCom(res.data.result)
            }
         }   
        ).catch(
            error =>{
                console.log(error)
            }
        )
    }, [group])

  return (
    <div>
    <Loading
        open={loading}
    />
    <CreateEditSkillGroup
        open={open}
        handleClose={handleClose}
        id={id}
        categoryy={category}
        skillId={group}
    />
    <DeleteSkillCom
        open={open1}
        handleClose={handleClose1}
        id={id}
        name={category}
    />
      
        <div className=' rounded-xl border bg-white border-primary-color p-4 my-6'>
       <ToastContainer

       />
       <div className=' mb-5'>
        <Button
            name={'Create skill component'}
            onClick={() =>{
                setOpen(true)
            }}
        />
       </div>

       <div className=' mb-3 flex justify-end'>
            <div>
                <label className=' block mb-2'>Select Skill Group</label>
                <select
                className=' w-full bg-[#F7F8FB]  text-black font-bold outline-0 border border-gray-300 p-3 rounded-lg'
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                >
                {trans.map((e, index) =>{
                    return <option key={index} value={e.groupId}>{e.groupName}</option>
                })}

                </select>
            </div>
       </div>
      
     <h1 className=' text-primary-color font-bold text-[24px] mb-4'>List of Skill Component</h1>
    
     <Paper className=' mx-auto overflow-auto ' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="left">ShortName</StyledTableCell>
            <StyledTableCell align="left">Update</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {com
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tran) => (
                
            <TableRow
              key={tran.componentId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left">{tran.componentName}</TableCell>
            <TableCell align="left">{tran.shortCode}</TableCell>
              <TableCell align="left" 
              
              >
              <EditIcon 
              onClick={() =>{
                setId(tran.componentId)
                setCategory(tran.componentName)
                setOpen(true)
              }}
              className='cursor-pointer'/>
              <DeleteIcon
              onClick={() =>{
                setId(tran.componentId)
                setCategory(tran.componentName)
                setOpen1(true)
              }}
               className=' cursor-pointer'/>
              </TableCell>
           

              
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={com.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {com.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No Data</p>}
    </Paper>
    </div>
    </div>
  )
}

export default SkillComponentTable