import React from 'react'
import AssessmentTable from '../../Components/Assessment/AssessmentTable'

function Assessment() {
  return (
    <div>
      <AssessmentTable/>
    </div>
  )
}

export default Assessment