import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = 'https://api.lecturesoft.org/v1/api'

const liskSkillGroup = () =>{
    return axios.get(BASEURL + `/list_skill_groups`, { headers: authHeader() })
}
const deleteSkillGroup = (id) =>{
    return axios.get(BASEURL + `delete_skill_group?groupId=${id}` , { headers: authHeader() })
}
const getSingleSkillGroup = (id) =>{
    return axios.get(BASEURL + `/single_skill_group?groupId=${id}` , { headers: authHeader() })
}
const getListSkillComponent = (id) =>{
    return axios.get(BASEURL + `/list_skill_component?groupId=${id}` , { headers: authHeader() })
}
const deleteSkillComponent = (id) =>{
    return axios.get(BASEURL + `/delete_skill_component?componentId=${id}` , { headers: authHeader() })
}
const getListAllQuestion = () =>{
    return axios.get(BASEURL + `/list_all_questions` , { headers: authHeader() })
}
const deleteQuestion = (id) =>{
    return axios.get(BASEURL + `/delete_question?questionId=${id}` , { headers: authHeader() })
}
const getListAllUsers = () =>{
    return axios.get(BASEURL + `/list_all_teacher` , { headers: authHeader() })
}
const changeUserStatus = (id) =>{
    return axios.get(BASEURL + `/change_teacher_status?teacherId=${id}` , { headers: authHeader() })
}
const getAccessment = () =>{
    return axios.get(BASEURL + `/list_all_assessment` , { headers: authHeader() })
}
const getSingleAssesstment = (id) =>{
    return axios.get(BASEURL + `/single_teacher_assessment?assessmentId=${id}`, { headers: authHeader() })
}
const adminCount = () =>{
    return axios.get(BASEURL + `/admin_count`, { headers: authHeader() })
}
const getServices = {
    liskSkillGroup,
    deleteSkillGroup,
    getSingleSkillGroup,
    getListSkillComponent,
    deleteSkillComponent,
    getListAllQuestion,
    getListAllUsers,
    changeUserStatus ,
    deleteQuestion,
    getAccessment,
    getSingleAssesstment,
    adminCount
}

export default getServices