import  React, {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../Components/SelectValue/Loading';
import getServices from '../../Services/get-services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function DeleteSkillGroup({open, handleClose, id, name}) {
     
      const [loading, setLoading] = useState(false)
     const submit = () =>{
        setLoading(true)
        getServices.deleteSkillGroup(id).then(
            res =>{
                setLoading(false)
                if(res.data.statusCode === '200'){
                    toast.success(res.data.message, {
                        onClose: () => {
                          window.location.reload()
                        }
                      })
                }else{
                    setLoading(false)
                    toast.error(res.data.message,
                        {
                          position: toast.POSITION.TOP_LEFT
                        })
                }
            }    
        ).catch(
            error =>{
                setLoading(false)
            }
        )
     }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
          open={loading} />
         
            <div className=' '>
           
                <div className=' flex justify-end mb-3'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div className='grid  p-3 md:p-0 place-items-center'>
                  
                        <p className=' text-[18px] font-[500] text-back-color2 mb-4 text-center'>Are you sure want to delete {name} ?</p>
                    
                </div>
              
              
                <div className=' mt-16 mb-8 grid grid-cols-2 gap-6'>
                        <p className=' bg-green-700 text-white p-3 rounded-lg text-center' 
                        onClick={() =>{
                            submit()
                        }}>Yes</p>
                        <p className=' bg-red-500 text-white p-3 rounded-lg text-center'
                        onClick={handleClose}
                        > No</p>
                   
                </div>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}