import React from 'react'
import UserTable from '../../Components/Users/UsersTable'

function Users() {
  return (
    <div>
      <UserTable/>
    </div>
  )
}

export default Users