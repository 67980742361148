import React from 'react'
import SkillComponentTable from '../../Components/SkillComponent/SkillComponentTable'

function SkilledComponent() {
  return (
    <div>
      <SkillComponentTable/>
    </div>
  )
}

export default SkilledComponent