import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainDashboard from '../MainDashboard'
import Question from '../Question'
import SkillGroup from '../SkillGroup/Index'
import SkilledComponent from '../SkillComponent'
import Users from '../Users'
import Assessment from '../Assessment'
import PastQuestion from '../Assessment/PastQuestion'
import ReportsDetials from '../../Components/Assessment/ReportsDetials'

function Routess() {
  return (
    <div>
    <Routes>
      <Route path='/dashboard' element={<MainDashboard/>}/>
      <Route path='/question' element={<Question/>}/>
      <Route path='/skillgroup' element={<SkillGroup/>}/>
      <Route path='/skillcomponent' element={<SkilledComponent/>}/>
      <Route path='/assessment' element={<Assessment/>}/>
      <Route path='/assessment/pastquestion/:id/:name' element={<PastQuestion/>}/>
      <Route path='/assessment/reportdetails/:id/:name' element={<ReportsDetials/>}/>
      <Route path='/users' element={<Users/>}/>
    </Routes>
       
    </div>
  )
}

export default Routess