import  React, {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../Components/SelectValue/Loading';
import postServices from '../../Services/post-services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function AddUsers({open, handleClose, }) {
     
      const [loading, setLoading] = useState(false)
    
     
     const  handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'text/csv') {
          // Handle the CSV file
          setLoading(true)
       postServices.bulkUser(file).then(
            res =>{
                setLoading(false)
                if(res.data.statusCode === '200'){
                    toast.success(res.data.message, {
                        onClose: () => {
                          window.location.reload()
                        }
                      })
                    }else{
                    setLoading(false)
                    toast.error(res.data.message,
                        {
                          position: toast.POSITION.TOP_LEFT
                        })
                }
            }    
        ).catch(
            error =>{
                setLoading(false)
            }
        )
        } else {
          alert('Please select a CSV file.');
        }
      };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
          open={loading} />
         
            <div className=' '>
           
                <div className=' flex justify-end mb-3'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div className=' mb-3'>
                    <h1 className=' text-center text-2xl mb-3'>Add bulk users</h1>
                    <div>
                        <label className=' block mb-3 text-black' htmlFor="csvFileInput">Upload file</label>
                        <input
                        type="file"
                        id="csvFileInput"
                        className=' w-full bg-[#F7F8FB] text-[#14013A]  outline-0 border border-gray-300 p-3 rounded-lg'
                        accept=".csv"
                        onChange={handleFileChange}
                        />
                    </div>
                </div>
                {/* <div className=' flex justify-end'>
                <Button
                        name={"Add"}
                    />
                </div> */}
                   
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}