import React from 'react'
import { Link } from 'react-router-dom'

function DashCom({name, count, link}) {
  return (
    <div>
    <Link to={link}>
    <div className=' p-3 py-5 rounded-lg my-3 bg-[#0E1130] text-white'>
        <h4 className=' text-2xl mb-3'>{count}</h4>
        <p className=' text-xl'>{name}</p>
    </div>
    </Link>
    </div>
  )
}

export default DashCom